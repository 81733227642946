<style lang="scss" scoped>
.mdc-button--dense {
  height: 24px;
  line-height: 24px;
  min-width: unset;
  padding: 0 4px;
}
.secondary {
  background-color: theme('colors.secondary');
}
.danger {
  @apply text-white bg-error;
}
button:disabled,
button:disabled i {
  @apply text-gray-dark;
}
</style>

<template>
  <button
    :disabled="disabled"
    :class="{
      'mdc-button--raised': raised,
      'mdc-button--dense': dense,
      secondary: secondary && raised,
      danger,
    }"
    class="mdc-button"
    type="button"
  >
    <slot />
  </button>
</template>

<script>
import { MDCRipple } from '@material/ripple';

export default {
  props: {
    ripple: {
      type: Boolean,
      default: false,
    },
    raised: {
      type: Boolean,
      default: false,
    },
    secondary: {
      type: Boolean,
      default: false,
    },
    danger: {
      type: Boolean,
      default: false,
    },
    dense: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    mdcRipple: null,
  }),
  mounted() {
    if (this.ripple) this.mdcRipple = new MDCRipple(this.$el);
  },
  beforeDestroy() {
    if (this.mdcRipple) this.mdcRipple.destroy();
  },
};
</script>
