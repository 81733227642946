import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store';

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  linkActiveClass: 'mdc-list-item--selected',
  routes: [
    // home routes
    {
      path: '/',
      meta: {
        title: 'Home',
        layout: 'home',
      },
      component: () => import('@/views/home/index.vue'),
    },
    {
      path: '/terms',
      meta: {
        title: 'Terms & Conditions',
      },
      component: () => import('@/views/home/terms.vue'),
    },
    {
      path: '/faq',
      meta: {
        title: 'FAQ',
      },
      component: () => import('@/views/faq.vue'),
    },
    {
      path: '/login',
      meta: {
        title: 'Login',
        layout: 'home',
      },
      component: () => import('@/views/home/login.vue'),
    },
    {
      path: '/register',
      meta: {
        title: 'Sign Up',
        layout: 'home',
      },
      component: () => import('@/views/home/register.vue'),
    },
    {
      path: '/forgot-password/:email?',
      meta: {
        title: 'Forgot Password',
        layout: 'home',
      },
      component: () => import('@/views/home/forgot-password.vue'),
    },
    {
      path: '/verify/:email/:token/:reset?',
      meta: {
        title: 'Verify',
        layout: 'home',
      },
      component: () => import('@/views/home/verify.vue'),
    },

    // app routes
    {
      path: '/email',
      meta: {
        title: 'Emails',
        layout: 'app',
      },
      components: {
        default: () => import('@/views/email/index.vue'),
        sidebar: () => import('@/views/email/sidebar.vue'),
      },
    },
    {
      path: '/email/logs/:hash?',
      meta: {
        title: 'Email Logs',
        layout: 'app',
      },
      components: {
        default: () => import('@/views/logs/index.vue'),
        sidebar: () => import('@/views/logs/sidebar.vue'),
      },
    },
    {
      path: '/email/contacts',
      meta: {
        title: 'Contacts',
        layout: 'app',
      },
      components: {
        default: () => import('@/views/contacts/index.vue'),
        sidebar: () => import('@/views/contacts/sidebar.vue'),
      },
    },
    {
      path: '/email/create',
      meta: {
        title: 'New Email',
        layout: 'app',
      },
      component: () => import('@/views/email/create.vue'),
    },
    {
      path: '/email/:hash',
      meta: {
        title: 'Email',
        layout: 'app',
      },
      components: {
        default: () => import('@/views/email/edit.vue'),
        sidebar: () => import('@/views/email/sidebar.vue'),
      },
    },
    {
      path: '/post',
      meta: {
        title: 'Social Posts',
        layout: 'app',
      },
      components: {
        default: () => import('@/views/posts/index.vue'),
        sidebar: () => import('@/views/posts/sidebar.vue'),
      },
    },
    {
      path: '/post/:hash',
      meta: {
        title: 'Social Post',
        layout: 'app',
      },
      components: {
        default: () => import('@/views/posts/edit.vue'),
        sidebar: () => import('@/views/posts/sidebar.vue'),
      },
    },
    {
      path: '/account',
      redirect: '/account/email',
    },
    {
      path: '/account/subscription',
      meta: {
        title: 'Account Subscription',
        layout: 'app',
      },
      components: {
        default: () => import('@/views/account/subscription.vue'),
        sidebar: () => import('@/views/account/sidebar.vue'),
      },
    },
    {
      path: '/account/email',
      meta: {
        title: 'Account Settings',
        layout: 'app',
      },
      components: {
        default: () => import('@/views/account/index.vue'),
        sidebar: () => import('@/views/account/sidebar.vue'),
      },
    },
    {
      path: '/account/email/:hash',
      meta: {
        title: 'Account Settings',
        layout: 'app',
      },
      components: {
        default: () => import('@/views/account/email.vue'),
        sidebar: () => import('@/views/account/sidebar.vue'),
      },
    },
    {
      path: '/account/password',
      meta: {
        title: 'Account Password',
        layout: 'app',
      },
      components: {
        default: () => import('@/views/account/password.vue'),
        sidebar: () => import('@/views/account/sidebar.vue'),
      },
    },
    {
      path: '/contact-us',
      meta: {
        title: 'Contact Us',
        layout: 'app',
      },
      components: {
        default: () => import('@/views/contact-us.vue'),
      },
    },
    {
      path: '*',
      meta: {
        title: 'Page not found',
        layout: 'home',
      },
      component: () => import('@/views/404.vue'),
    },
  ],
});

router.beforeEach((to, from, next) => {
  document.title = `${to.meta.title} | Later.io`;

  if (to.meta.layout === 'app' && !store.state.user)
    store.dispatch('refreshUser').then(() => next());
  else next();
});

export default router;
