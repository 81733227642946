<template>
  <div id="app" class="h-full">
    <component :is="layout">
      <router-view />
    </component>
  </div>
</template>

<script>
export default {
  components: {
    'home-layout': () => import('@/views/home.vue'),
    'app-layout': () => import('@/views/app.vue'),
  },
  computed: {
    layout() {
      return `${
        this.$route.meta.layout || (this.$store.state.user ? 'app' : 'home')
      }-layout`;
    },
  },
};
</script>
