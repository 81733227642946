import Vue from 'vue';
import router from '@/router';
import MdcDialog from '@/components/mdc/dialog.vue';

export function confirmDialog(title, description, acceptLabel = 'Yes') {
  return dialogHelper(title, description, 'confirm', acceptLabel);
}

export function alertDialog(title, description) {
  return dialogHelper(title, description, 'alert');
}

export function upgradeDialog(title, description) {
  return dialogHelper(title, description, 'alert', 'Upgrade!').then(() =>
    router.push('/account/subscription')
  );
}

function dialogHelper(title, description, type, acceptLabel = 'OK') {
  const DialogConstructor = Vue.extend(MdcDialog);
  const dialog = new DialogConstructor();
  dialog.type = type;
  dialog.title = title;
  dialog.description = description;
  dialog.acceptLabel = acceptLabel;
  dialog.$mount(document.body.appendChild(document.createElement('div')));

  return new Promise((resolve, reject) => {
    dialog.mdcDialog.listen('MDCDialog:closing', (event) => {
      dialog.$el.remove();
      dialog.$destroy();
      if (event && event.detail && event.detail.action === 'accept') resolve();
      else reject();
    });
  });
}
