import axios from 'axios';
import { DateTime } from 'luxon';
import store from '@/store';
import { alertDialog, upgradeDialog } from '@/utils/dialog';

axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
axios.defaults.headers.common['X-Client-Timezone'] = DateTime.local().zoneName;
axios.defaults.headers.common['X-Client-Locale'] = DateTime.local().locale;

axios.interceptors.request.use((request) => {
  store.commit('loading', true);
  return request;
});

axios.interceptors.response.use(
  (response) => {
    store.commit('loading', false);
    store.commit('currentTime', DateTime.fromHTTP(response.headers.date));
    return response;
  },
  (error) => {
    store.commit('loading', false);
    store.commit('currentTime', DateTime.fromHTTP(error.response.headers.date));

    if ([403].includes(error.response.status)) {
      // ignore these.  403 when bad password is given
    } else if ([401, 419].includes(error.response.status))
      store.dispatch('logout', {
        nextPath: '/login',
        message: 'Session has expired.',
      });
    else if (error.response.status === 422 && error.response.data.errors) {
      let str = '<ul>';
      Object.values(error.response.data.errors).forEach((arr) => {
        arr.forEach((err) => {
          str += `<li>${err}</li>`;
        });
      });
      str += '</ul>';
      alertDialog('Whoops!', str);
    } else if (error.response.status === 429)
      upgradeDialog('Limit Reached', error.response.data.message);
    else if (error.response.status === 409) alertDialog(error.response.data);
    else if (error.response.status === 451)
      alertDialog(
        'Account Disabled',
        'Your account has been flagged for abuse.  If you feel this is an error, contact us at lettermelater@gmail.com'
      );
    else if (error.response.status === 503)
      alertDialog('We are down temporarily, sit tight!');
    else
      alertDialog(
        `${error.response.status} Error`,
        `${error.response.statusText} - ${error.response.data.message}`
      );

    return Promise.reject(error);
  }
);

export default axios;
