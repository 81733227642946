import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import axios from '@/utils/xhr';
import router from '@/router';
import Bugsnag from '@bugsnag/js';

Vue.use(Vuex);

export default new Vuex.Store({
  strict: process.env.NODE_ENV !== 'production',
  plugins: [
    createPersistedState({
      paths: ['isLoggedIn', 'sidebarOpen', 'email', 'contacts', 'logs'], // items in this list will persist through a page reload
    }),
  ],
  state: {
    user: null,
    isLoggedIn: false,
    loading: false,
    sidebarOpen: true,
    currentTime: null,
    snackbarMessage: null,
    mailerOptions: [
      { id: 'unsigned', label: 'Later.io (unsigned)' },
      { id: 'signed', label: 'Later.io (signed)' },
      { id: 'smtp', label: 'SMTP' },
    ],
    fallbackOptions: [
      { id: 'unsigned', label: 'Later.io (unsigned)' },
      { id: 'signed', label: 'Later.io (signed)' },
      { id: 'quit', label: 'Retry' },
    ],
    faqOpen: null,
  },
  getters: {
    verifiedAccounts: (state) => {
      if (!state.user || !state.user.accounts || state.user.accounts.length < 1)
        return [];
      return state.user.accounts.filter((account) => account.verified === 1);
    },
  },
  mutations: {
    user(state, user) {
      state.user = user;
      state.isLoggedIn = !!user;
      if (user)
        Bugsnag.setUser(
          user.hash,
          user.accounts[0].email,
          user.accounts[0].name
        );
      else Bugsnag.setUser(null);
    },
    loading(state, loading) {
      state.loading = loading;
    },
    sidebarOpen(state, sidebarOpen) {
      state.sidebarOpen = sidebarOpen;
    },
    currentTime(state, currentTime) {
      state.currentTime = currentTime;
    },
    snackbarMessage(state, snackbarMessage) {
      state.snackbarMessage = snackbarMessage;
    },
    faqOpen(state, faqOpen) {
      state.faqOpen = state.faqOpen === faqOpen ? null : faqOpen;
    },
  },
  actions: {
    refreshUser({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get('/user-data')
          .then((response) => {
            commit('user', response.data);
            resolve(response);
          })
          .catch((response) => {
            reject(response);
          });
      });
    },
    logout(
      { commit },
      { nextPath = '/', message = 'Successfully logged out.' }
    ) {
      return new Promise((resolve) => {
        axios.get('/logout').finally((response) => {
          router.push(nextPath, () => {
            commit('user', null);
            commit('snackbarMessage', message);
          });
          resolve(response);
        });
      });
    },
  },
  modules: {
    email: {
      namespaced: true,
      state: {
        total: 0,
        pagination: {
          view: 'all',
          page: 1,
          sort: {
            by: 'updated_at',
            dir: 'desc',
          },
        },
      },
      mutations: {
        setTotal(state, total) {
          state.total = total;
        },
        setSearch(state, search) {
          state.pagination.search = search;
        },
        setView(state, view) {
          state.pagination.view = view;
          state.pagination.search = '';
          state.pagination.page = 1;
        },
        setSort(state, by) {
          if (state.pagination.sort.by === by)
            state.pagination.sort.dir =
              state.pagination.sort.dir === 'desc' ? 'asc' : 'desc';
          else state.pagination.sort.dir = by.endsWith('_at') ? 'desc' : 'asc';
          state.pagination.sort.by = by;
        },
        nextPage(state, direction) {
          state.pagination.page += direction;
        },
      },
    },
    contacts: {
      namespaced: true,
      state: {
        total: 0,
        pagination: {
          search: '',
          view: 'all',
          page: 1,
          sort: {
            by: 'name',
            dir: 'asc',
          },
        },
        groups: [],
      },
      mutations: {
        setTotal(state, total) {
          state.total = total;
        },
        setSearch(state, search) {
          state.pagination.search = search;
        },
        setView(state, view) {
          state.pagination.view = view;
          state.pagination.search = '';
          state.pagination.page = 1;
        },
        setSort(state, by) {
          if (state.pagination.sort.by === by)
            state.pagination.sort.dir =
              state.pagination.sort.dir === 'desc' ? 'asc' : 'desc';
          else
            state.pagination.sort.dir =
              by.endsWith('_at') || by.endsWith('_count') ? 'desc' : 'asc';
          state.pagination.sort.by = by;
        },
        nextPage(state, direction) {
          state.pagination.page += direction;
        },
        groups(state, groups) {
          state.groups = groups;
        },
        newContact() {},
        importContacts() {},
        newGroup() {},
      },
      getters: {
        selectedGroup: (state) =>
          state.groups.find((group) => group.hash === state.pagination.view),
      },
    },
    logs: {
      namespaced: true,
      state: {
        total: 0,
        pagination: {
          view: 'all',
          page: 1,
        },
      },
      mutations: {
        setTotal(state, total) {
          state.total = total;
        },
        setView(state, view) {
          state.pagination.view = view;
          state.pagination.page = 1;
        },
        nextPage(state, direction) {
          state.pagination.page += direction;
        },
      },
    },
  },
});
