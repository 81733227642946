export function capitalize(word) {
  return word.charAt(0).toUpperCase() + word.slice(1);
}

export function plural(word, count) {
  return count === 1 ? word : `${word}s`;
}

export function humanBytes(bytes) {
  const sizes = ['bytes', 'KB', 'MB', 'GB', 'TB'];
  if (bytes === 0) return '0';
  const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 0);
  return `${Math.round(bytes / 1024 ** i, 2)} ${sizes[i]}`;
}
