<template>
  <aside
    class="mdc-dialog"
    role="alertdialog"
    aria-modal="true"
    aria-labelledby="my-dialog-title"
    aria-describedby="my-dialog-content"
  >
    <div class="mdc-dialog__container">
      <div :class="classes" class="mdc-dialog__surface">
        <h2 id="my-dialog-title" class="mdc-dialog__title" v-html="title" />
        <template v-if="['alert', 'confirm'].includes(type)">
          <div
            id="my-dialog-content"
            class="mdc-dialog__content"
            v-html="description"
          />
          <footer class="mdc-dialog__actions">
            <mdc-button
              v-if="type === 'confirm'"
              class="mdc-dialog__button"
              data-mdc-dialog-action="cancel"
              v-text="acceptLabel === 'Yes' ? 'No' : 'Cancel'"
            />
            <mdc-button
              raised
              class="mdc-dialog__button mdc-dialog__button--default"
              data-mdc-dialog-action="accept"
              v-text="acceptLabel"
            />
          </footer>
        </template>
        <template v-else>
          <form @submit.prevent="$emit('submit', $event)">
            <div id="my-dialog-content" class="mdc-dialog__content">
              <slot />
            </div>
            <footer class="mdc-dialog__actions">
              <mdc-button type="submit"> Submit </mdc-button>
            </footer>
          </form>
        </template>
      </div>
    </div>
    <div class="mdc-dialog__scrim" />
  </aside>
</template>

<script>
import { MDCDialog } from '@material/dialog';
import MdcButton from '@/components/mdc/button.vue';

export default {
  components: { MdcButton },
  props: {
    title: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'alert',
      validator: (value) => ['alert', 'confirm', 'form'].includes(value),
    },
    acceptLabel: {
      type: String,
      default: 'OK',
    },
    classes: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    mdcDialog: null,
  }),
  mounted() {
    this.mdcDialog = new MDCDialog(this.$el);
    this.mdcDialog.open();
    this.mdcDialog.listen('MDCDialog:closing', (event) => {
      this.$emit('closing', event);
    });
  },
  beforeDestroy() {
    this.mdcDialog.destroy();
  },
};
</script>
