import Vue from 'vue';

import '@/sass/app.scss';
import '@/utils/string';

import router from '@/router';
import store from '@/store';

import Main from '@/views/main.vue';

import Bugsnag from '@bugsnag/js';
import BugsnagPluginVue from '@bugsnag/plugin-vue';

Bugsnag.start({
  apiKey: '9eff31b15528a52c6000c1bda7b4bbe6',
  plugins: [new BugsnagPluginVue()],
});

/* eslint no-new: "off" */
new Vue({
  router,
  store,
  mounted: () => {
    Bugsnag.getPlugin('vue').installVueErrorHandler(Vue);
    document.dispatchEvent(new Event('x-app-rendered'));
  },
  render: (h) => h(Main),
}).$mount('#app');
